<template>
  <span class="w-100">
    <!-- Contenteditable div to capture and display the pasted image -->
    <b-form-input
      ref="textarea"
      v-model="copiedImage"
      class="form-control"
      :rows="1"
      max-rows="8"
      tag="pre"
      style="resize:none;"
      placeholder="Message"
      autocomplete="off"
      @paste="handlePaste"
      @keydown.stop="$emit('onTyping')"
    />
  </span>
</template>

<script>
import { BFormInput } from 'bootstrap-vue'
import useChat from '../../useChat'

const { GET_MSG_TYPE } = useChat()
export default {
  components: {
    BFormInput,
  },
  props: {
    value: {
      type: String,
      default: () => '',
    },
    activeContact: {
      type: String,
      required: true,
    },
  },
  computed: {
    copiedImage: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    activeContact: {
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.$refs.textarea.focus()
          }, 250)
        }
      },
      immediate: true,
    },
  },
  methods: {
    handlePaste(event) {
      const { files } = event.clipboardData || window.clipboardData
      if (files.length) {
        event.preventDefault()
        files.forEach(file => {
          if (file.type.indexOf('image') !== -1) {
            const blob = new Blob([file], { type: file.type })
            const url = URL.createObjectURL(blob)
            this.$emit('updateSnap', file, url)
          } else {
            const data = {
              file,
              type: GET_MSG_TYPE('ATTACHMENT', file, 'type'),
            }
            this.$emit('updateSnap', data)
          }
        })
      }
    },
  },
}
</script>
