<template>
  <div class="stickers-wrapper">
    <!-- Top Bar Categories -->
    <div class="top-bar position-absolute top-0 w-100">
      <div class="categories border-bottom text-center">
        <b-row class="justify-content-start px-1">
          <b-col
            v-for="(stickerCategory, index) in stickers"
            :id="`category-${stickerCategory.category}`"
            :key="`category-${stickerCategory.category}`"
            cols="2"
            class="category-icon-wrapper cursor-pointer px-0"
            :class="{active: (currentStickerCategoryIndex === index)}"
            @click="gotoCategory(index)"
          >
            <a
              v-b-tooltip.hover="IS_MOBILE() ? '' : CAPITALIZE(stickerCategory.category)"
              href="#"
            >
              <img
                :src="stickerCategory.stickers[0].sticker"
                height="32px"
                width="32px"
                alt=""
              >
            </a>
          </b-col>
        </b-row>
      </div>
      <!-- Search Bar -->
      <div class="position-relative">
        <b-form-input
          v-model="searchTerm"
          placeholder="Search"
          @input="searchStickers"
        />
        <feather-icon
          v-if="searchTerm"
          icon="XIcon"
          class="clear-icon"
          size="16"
          @click="resetSearch"
        />
      </div>
      <p class="current-category font-weight-bold mb-0">
        {{ searchTerm ? 'Search Results' : CAPITALIZE(stickers[currentStickerCategoryTitleIndex].category) }}
      </p>
    </div>

    <!-- Stickers from Search Result -->
    <div
      ref="stickerContainer"
      class="sticker-container-wrapper px-1"
    >
      <b-overlay :show="showOverlay">
        <!-- Stickers -->
        <div class="mt-2">
          <p
            v-if="searchTerm && !searchResults.length"
            class="mt-4 text-center"
          >
            No Stickers Found!
          </p>
          <div
            v-for="(stickerCategory, index) in searchTerm ? searchResults : stickers"
            ref="stickerCategory"
            :key="stickerCategory.category"
          >
            <p
              :id="stickerCategory.category"
              class="mt-1 mb-0 font-weight-bold"
              :class="{ 'mt-3': searchTerm && !index, 'no-opacity': !index && !searchTerm }"
            >
              {{ CAPITALIZE(stickerCategory.category) }}
            </p>

            <b-row class="stickers-row">
              <b-col
                v-for="sticker in stickerCategory.stickers"
                :key="sticker.code"
                v-b-tooltip.hover="IS_MOBILE() ? '' : CAPITALIZE(sticker.code.split('-').join(' '))"
                cols="3"
                class="sticker cursor-pointer text-center mb-1"
                @click="selectSticker(`${stickerCategory.category}.${sticker.code}`)"
              >
                <img
                  :src="sticker.sticker"
                  class="object-fit w-100 h-100"
                  alt=""
                >
              </b-col>
            </b-row>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import stickers from '@/const/stickers'
import {
  getCurrentInstance,
  inject,
  onMounted,
  reactive, ref, toRefs, watch,
} from 'vue'
import {
  BRow, BCol, BFormInput, BOverlay, VBTooltip,
} from 'bootstrap-vue'
import _ from 'lodash'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    activeContact: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const root = getCurrentInstance().proxy.$root
    const instance = getCurrentInstance().proxy
    const chatService = inject('$chatService')
    const state = reactive({
      stickers,
      currentStickerCategoryIndex: 0,
      currentStickerCategoryTitleIndex: 0,
      categoryClicked: false,
      searchResults: [],
      showOverlay: false,
    })
    const searchTerm = ref('')
    const searchTimeout = () => {}

    // Reset Search Input and Results
    const resetSearch = () => {
      state.searchResults = []
      searchTerm.value = ''
    }

    // Send Sticker
    const selectSticker = async sticker => {
      const messageRequestId = await chatService.sendStickerInRoom(props.activeContact.roomUid, sticker)
      const payload = {
        messageRequestId,
        message: sticker,
        type: 'STICKER',
      }
      root.$emit('sticker-sent', payload)
    }

    // Search Sticker
    const searchStickers = _.debounce(() => {
      state.searchResults = state.stickers.map(category => ({
        ...category,
        stickers: category.stickers.filter(sticker => sticker.sticker.includes(searchTerm.value)),
      })).filter(category => category.stickers.length)
    }, 1000)

    // Go to sticker category when clicked
    const gotoCategory = index => {
      if (searchTerm.value) resetSearch()
      state.categoryClicked = true
      state.currentStickerCategoryIndex = index
      root.$nextTick(() => {
        const element = instance.$refs.stickerCategory[index]
        element.scrollIntoView()
      })
    }

    watch(searchTerm, val => {
      clearTimeout(searchTimeout)
      if (val) {
        state.showOverlay = true
        setTimeout(() => { state.showOverlay = false }, 1200)
      }
    })

    onMounted(() => {
      const container = instance.$refs.stickerContainer
      const stickerCategories = instance.$refs.stickerCategory

      if (stickerCategories) {
        container.addEventListener('scroll', () => {
          for (let i = 0; i < stickerCategories.length; i += 1) {
            if (container.scrollTop > stickerCategories[i].offsetTop - 50 && container.scrollTop < stickerCategories[i].offsetTop + stickerCategories[i].clientHeight - 50) {
              state.currentStickerCategoryTitleIndex = i
              if (!state.categoryClicked) {
                state.currentStickerCategoryIndex = i
              } else {
                setTimeout(() => { state.categoryClicked = false }, 500)
              }
            }
          }
        })
      }
    })

    return {
      ...toRefs(state),
      searchTerm,

      resetSearch,
      selectSticker,
      searchStickers,
      gotoCategory,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/pages/sticker-container.scss";
</style>
