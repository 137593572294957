<template>
  <div
    ref="chatRef"
    :style="`min-height:${fixedMinHeight ? fixedMinHeight : minHeight}px`"
  >
    <slot v-if="shouldRender" />
  </div>
</template>

<script>
import { useIntersectionObserver } from '@vueuse/core'
import { getCurrentInstance, reactive, ref, toRefs } from 'vue'

export default {
  props: {
    renderOnIdle: {
      type: Boolean,
      default: () => false,
    },
    unrender: {
      type: Boolean,
      default: () => false,
    },
    minHeight: {
      type: Number,
      default: () => 0,
    },
    unrenderDelay: {
      type: Number,
      default: () => 2000,
    },
  },
  setup(props) {
    const root = getCurrentInstance().proxy.$root
    const state = reactive({
      shouldRender: false,
      fixedMinHeight: 0,
    })
    const chatRef = ref()
    let renderTimer
    let unrenderTimer

    const onIdle = callback => {
      if ('requestIdleCallback' in window) {
        window.requestIdleCallback(callback)
      } else {
        setTimeout(() => {
          root.$nextTick(callback)
        }, 300)
      }
    }

    const { stop } = useIntersectionObserver(
      chatRef,
      ([{ isIntersecting }]) => {
        if (isIntersecting) {
          // when the user re-scrolls to a component that was unrendered, stop the unrendering timer
          clearTimeout(unrenderTimer)
          if (props.unrender) {
            renderTimer = setTimeout(() => {
              state.shouldRender = true
            }, 10)
          } else {
            state.shouldRender = true
            stop()
          }
        } else if (props.unrender) {
          clearTimeout(renderTimer)
          unrenderTimer = setTimeout(() => {
            state.fixedMinHeight = chatRef.value?.clientHeight
            state.shouldRender = false
          }, props.unrenderDelay)
        }
      },
      {
        rootMargin: '300px',
      },
    )

    if (props.renderOnIdle) {
      onIdle(() => {
        state.shouldRender = true
        if (!props.unrender) stop()
      })
    }

    return {
      ...toRefs(state),
      chatRef,
    }
  },
}
</script>
