import gql from "graphql-tag";

export default {
  MY_SCREEN_CASTS: gql`
    query activities(
      $startTime: DateTime!
      $endTime: DateTime!
      $keyword: [String!]
      $uuid: String
      $page: Int # $first: Int
      $timezone: String
    ) {
      me(uuid: $uuid) {
        id
        uuid
        recordsDuration: activityDuration(
          between: {
            startTime: $startTime
            endTime: $endTime
            timezone: $timezone
          }
          project: { keyword: $keyword, searchKey: UUID }
        )
        manualDuration: recordsDuration(
          activityType: MANUAL
          between: {
            startTime: $startTime
            endTime: $endTime
            timezone: $timezone
          }
          project: { keyword: $keyword, searchKey: UUID }
        )
        productiveDuration: recordsDuration(
          productivity: PRODUCTIVE
          between: {
            startTime: $startTime
            endTime: $endTime
            timezone: $timezone
          }
          project: { keyword: $keyword, searchKey: UUID }
        )
        unproductiveDuration: recordsDuration(
          productivity: UNPRODUCTIVE
          between: {
            startTime: $startTime
            endTime: $endTime
            timezone: $timezone
          }
          project: { keyword: $keyword, searchKey: UUID }
        )
        neutralDuration: recordsDuration(
          productivity: NEUTRAL
          between: {
            startTime: $startTime
            endTime: $endTime
            timezone: $timezone
          }
          project: { keyword: $keyword, searchKey: UUID }
        )
        activityRecords(
          activityType: SCREENCAST
          orderBy: { column: ENDING_TIME, order: DESC }
          between: {
            startTime: $startTime
            endTime: $endTime
            timezone: $timezone
          }
          project: { keyword: $keyword, searchKey: UUID }
          page: $page
        ) {
          total
          currentPage
          hasMorePages
          data {
            duration
            mouseMoves
            memo
            keyClicks
            startedAt
            endedAt
            record {
              ... on Screencast {
                uuid
                title
              }
            }
          }
        }
      }
    }
  `,
  GET_DURATION: gql`
    query getDurations(
      $startTime: DateTime!
      $endTime: DateTime!
      $keyword: [String!]
      $uuid: String
      $timezone: String
    ) {
      me(uuid: $uuid) {
        id
        recordsDuration: activityDuration(
          between: {
            startTime: $startTime
            endTime: $endTime
            timezone: $timezone
          }
          project: { keyword: $keyword, searchKey: UUID }
        )
        manualDuration: recordsDuration(
          activityType: MANUAL
          between: {
            startTime: $startTime
            endTime: $endTime
            timezone: $timezone
          }
          project: { keyword: $keyword, searchKey: UUID }
        )
        productiveDuration: recordsDuration(
          productivity: PRODUCTIVE
          between: {
            startTime: $startTime
            endTime: $endTime
            timezone: $timezone
          }
          project: { keyword: $keyword, searchKey: UUID }
        )
        unproductiveDuration: recordsDuration(
          productivity: UNPRODUCTIVE
          between: {
            startTime: $startTime
            endTime: $endTime
            timezone: $timezone
          }
          project: { keyword: $keyword, searchKey: UUID }
        )
      }
    }
  `,

  GET_ACTIVITY_BY_TYPE: gql`
    query userActivityByType(
      $keyword: [String!]
      $page: Int
      $first: Int
      $productivity: Productivity
      $uuid: String
      $startTime: DateTime!
      $endTime: DateTime!
      $timezone: String
    ) {
      me(uuid: $uuid) {
        name
        email
        id
        topUses(
          activityType: [WEBSITE, APPLICATION]
          productivity: $productivity
          between: {
            startTime: $startTime
            endTime: $endTime
            timezone: $timezone
          }
          project: { keyword: $keyword, searchKey: UUID }
          page: $page
          first: $first
        ) {
          total
          count
          data {
            duration
            activityType
            name
            productivity
          }
        }
      }
    }
  `,
  GET_DAILY_ACTIVITIES: gql`
    query dailyActivities(
      $startTime: DateTime!
      $endTime: DateTime!
      $userId: String!
      $projectId: [String!]
      $timezone: String
    ) {
      user(uuid: $userId) {
        id
        dailyActivities(
          project: { keyword: $projectId, searchKey: UUID }
          between: {
            startTime: $startTime
            endTime: $endTime
            timezone: $timezone
          }
          first: -1
        ) {
          data {
            date
            duration
          }
        }
      }
    }
  `,
  GET_ALL_DAILY_ACTIVITIES: gql`
    query allDailyActivities(
      $startTime: DateTime!
      $endTime: DateTime!
      $userId: [String!]
      $projectId: [String!]
      $timezone: String
    ) {
      users(
        user: { searchKey: UUID, type: EXACT, keyword: $userId }
        project: { keyword: $projectId, searchKey: UUID }
      ) {
        data {
          id
          uuid
          name
          avatar
          isOnline(project: { keyword: $projectId, searchKey: UUID })
          dailyActivities(
            project: { keyword: $projectId, searchKey: UUID }
            between: {
              startTime: $startTime
              endTime: $endTime
              timezone: $timezone
            }
            first: -1
          ) {
            data {
              date
              duration
              idlePercent
            }
          }
        }
      }
    }
  `,
  GET_IDLE_TIME: gql`
    query idleTime(
      $uuid: String
      $keyword: [String!]
      $startTime: DateTime!
      $endTime: DateTime!
      $timezone: String
    ) {
      user(uuid: $uuid) {
        uuid
        # idleDuration(
        #   between: { startTime: $startTime, endTime: $endTime, timezone: $timezone }
        #   project: { searchKey: UUID, keyword: $keyword }
        # )
        idlePercent(
          between: {
            startTime: $startTime
            endTime: $endTime
            timezone: $timezone
          }
          project: { searchKey: UUID, keyword: $keyword }
        )
        unproductiveDuration: recordsDuration(
          project: { searchKey: UUID, keyword: $keyword }
          productivity: UNPRODUCTIVE
          between: {
            startTime: $startTime
            endTime: $endTime
            timezone: $timezone
          }
        )
        recordsDuration: activityDuration(
          between: {
            startTime: $startTime
            endTime: $endTime
            timezone: $timezone
          }
          project: { keyword: $keyword, searchKey: UUID }
        )
        recordedDuration: recordsDuration(
          between: {
            startTime: $startTime
            endTime: $endTime
            timezone: $timezone
          }
          project: { keyword: $keyword, searchKey: UUID }
        )
        manualDuration: recordsDuration(
          activityType: MANUAL
          between: {
            startTime: $startTime
            endTime: $endTime
            timezone: $timezone
          }
          project: { keyword: $keyword, searchKey: UUID }
        )
        topUses(
          activityType: [WEBSITE, APPLICATION]
          between: {
            startTime: $startTime
            endTime: $endTime
            timezone: $timezone
          }
          project: { searchKey: UUID, keyword: $keyword }
        ) {
          data {
            activityType
            name
            duration
            idlePercent
            productivity
          }
        }
      }
    }
  `,
  GET_WEEKLY_TIMELINE: gql`
    query getWeeklyTime(
      $keyword: [String!]
      $page: Int
      $startTime: DateTime!
      $endTime: DateTime!
      $timezone: String
      $projectId: [String!]
    ) {
      users(
        user: { searchKey: UUID, keyword: $keyword, type: EXACT }
        project: { keyword: $projectId, searchKey: UUID }
        page: $page
      ) {
        data {
          name
          uuid
          avatar
          isOnline(project: { keyword: $projectId, searchKey: UUID })
          dailyActivities(
            between: {
              startTime: $startTime
              endTime: $endTime
              timezone: $timezone
            }
            project: { keyword: $projectId, searchKey: UUID }
            first: -1
          ) {
            data {
              date
              duration
              # recordsDuration
              idlePercent
            }
          }
        }
      }
    }
  `,
  GET_WORK_PATTERN: gql`
    query getWorkPattern(
      $startTime: DateTime!
      $endTime: DateTime!
      $projectId: [String!]
      $userId: String!
      $timezone: String
    ) {
      me(uuid: $userId) {
        activities(
          orderBy: { column: ENDING_TIME, order: DESC }
          between: {
            startTime: $startTime
            endTime: $endTime
            timezone: $timezone
          }
          project: { keyword: $projectId, searchKey: UUID }
          first: -1
        ) {
          data {
            uuid
            startedAt
            manualDuration: recordsDuration(activityType: MANUAL)
            endedAt
            deletedTimings {
              data {
                startedAt
                endedAt
              }
            }
          }
        }
      }
    }
  `,

  GET_PENDING_MANUAL_TIME: gql`
    query pendingManualTime($projectId: String!, $userId: String!) {
      project(uuid: $projectId) {
        pendingManualTimes(user: { keyword: [$userId], searchKey: UUID }) {
          total
          data {
            id
            # uuid
            startedAt
            endedAt
            remarks
            user {
              uuid
            }
          }
        }
      }
    }
  `,
};
