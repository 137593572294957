export default {
  DATE_PICKER_FORMAT: {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    weekday: 'short',
  },
  DATE_FORMAT: 'MMM D, YYYY',
  DATE_TIME_FORMAT: 'MMM D, YYYY hh:mm A',
  MOBILE_DATE_PICKER_FORMAT: { year: 'numeric', month: 'short', day: '2-digit' },
}
