<template>
  <div>
    <b-skeleton-wrapper>
      <b-row
        v-for="index in count"
        :key="index"
        class="d-flex align-items-start my-2"
      >
        <b-col
          cols="3"
          :md="buttons ? '1' : '2'"
        >
          <b-skeleton
            class="avatar-skeleton"
            type="avatar"
            size="48px"
          />
        </b-col>

        <b-col
          :cols="buttons ? '6' : '9'"
          :md="buttons ? '9' : '10'"
        >
          <div class="w-100">
            <b-skeleton width="80%" />
            <b-skeleton width="60%" />
          </div>
        </b-col>

        <b-col
          v-if="buttons"
          cols="3"
          md="2"
        >
          <b-skeleton type="button" />
        </b-col>
      </b-row>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import {
  BSkeletonWrapper, BSkeleton, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BSkeletonWrapper,
    BSkeleton,
  },
  props: {
    count: {
      type: Number,
      default: () => 1,
    },
    buttons: {
      type: Boolean,
      default: () => false,
    },
  },
}
</script>

<style scoped>
/* .avatar-skeleton {
  width: "52px";
  height: "42px";
}

@media screen and (max-width: 768px) {
  .avatar-skeleton {
    width: "42px";
    height: "32px";
  }
} */
</style>
